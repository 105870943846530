const LoadingScreen = () => {
    const loadingScreenStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent background
        zIndex: 9999,
    };

    const loadingSpinnerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100px', 
        height: '100px',
    };

    const materialIconStyle = {
        fontSize: '80px',
        animation: 'spin 1s linear infinite',
    };

    const keyframes = `
      @keyframes spin {
        0% {
          transform: rotate(360deg);
        }
        100% {
          transform: rotate(0deg);
        }
      }
    `;

    return (
        <div style={loadingScreenStyle}>
            <style>{keyframes}</style>
            <div style={loadingSpinnerStyle}>
                <i className="material-icons" style={materialIconStyle}>
                    cached
                </i>
            </div>
        </div>
    );
};

export default LoadingScreen;