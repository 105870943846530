import React from 'react';

const PersonCard = ({ person, cardState, handleClick, admin, hideCard }) => {
  const { fullName, file } = person;

  const singleCardStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: cardState === 'grey' ? '#cccccc' : cardState === 'green' ? '#4CAF50' : admin ? '#9e2121' : 'blue',
    padding: '10px',
    width: '80%',
    height: '90%',
    color: 'white',
    margin: 'auto',
  };

  const photoCard = {
    width: '100%',
    height: '100%',
  };

  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '15%',
    border: '3px solid white',
  };

  const textStyle = {
    backgroundColor: 'white',
    padding: '1%',
    borderRadius: '5%',
    color: '#9e2121',
    margin: '5% 0',
    width: '100%',
    textAlign: 'center',
  };

  const selectedCardStyle = hideCard ? photoCard : singleCardStyle;

  return (
    <div style={selectedCardStyle} onClick={handleClick}>
      <img style={imageStyle} src={`data:image/jpeg;base64, ${file}`} alt={fullName} />
      {!hideCard &&
        <div style={textStyle}>
          <p style={{ margin: '0', fontWeight: 'bold' }}>{fullName}</p>
        </div>
      }
    </div>
  );
};

export default PersonCard;
