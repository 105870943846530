import React from 'react';
import { ReactSVG } from "react-svg";

const Icon = ({ iconPath, style }) => {
    return <ReactSVG
        src={iconPath}
        beforeInjection={(svg) => applyStyles(svg, style)}
    />
};

const applyStyles = (svg, style) => {
    if (style) {
        Object.keys(style).forEach((property) => {
            svg.setAttribute(property, style[property]);
        });
    }
};


export default Icon;