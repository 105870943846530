import React from 'react';
import image from '../Resources/GwConsid.svg';
import officeIcon from '../Resources/Stockholm2.svg';
import { HeaderGameTextStyle } from './GeneralStyles.js';
import Icon from './Icon';

const headerStyle = {
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '23vh',
    flex: '1',
    marginLeft: "9vh",
};

const GameHeader = ({ gameId, gameScore, streak, isTabletOrMobile, isLaptop, isBigScreen }) => {
    const style = HeaderGameTextStyle('center', isTabletOrMobile, isLaptop, isBigScreen);

    const flameTextStyle = {
        ...style,
        marginTop: "-2vh",
        animation: 'flameEffect 100s infinite alternate',
        color: '#B61642',
        textShadow: '0 0 5px #ff9900, 0 0 10px #ffcc00, 0 0 15px #ff6600, 0 0 20px #ffcc00, 0 0 25px #ff9900, 0 0 30px #ff3300, 0 0 35px #ff6600',
        fontSize: isBigScreen ? "2vh" : "3vh",
    };
    const iconStyle = {
        ...style,
        marginTop: "-0.1vh",
        color: "#701131",
        fontSize: isTabletOrMobile ? "1vh" : "2vh"
    }

    return (
        <div>
            <div style={{ display: 'flex' }}>
                <div style={headerStyle} />
                <div>
                    <Icon style={{ fill: '#701131' }} iconPath={officeIcon} />
                    <p style={iconStyle}><strong>Stockholm</strong></p>
                </div>
            </div>
            {streak !== 0 && <p style={flameTextStyle}><strong>{streak}</strong></p>}
            {gameId && <p style={style}>Spelrums id: {gameId}</p>}
            <div>
                {gameScore
                    ? Object.entries(gameScore).sort().map(([player, score]) => (
                        <div key={player} style={player === 'Streak' && score !== 0 ? flameTextStyle : style}>
                            {player}: {score}
                        </div>
                    ))
                    : null
                }
            </div>
        </div>
    );
};

export default GameHeader;
