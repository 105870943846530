import { useNavigate } from 'react-router-dom';

export const buttonStyles = {
    button18: {
        alignItems: 'center',
        backgroundColor: '#B61642',
        border: 0,
        borderRadius: '100px',
        boxSizing: 'border-box',
        color: '#ffffff',
        cursor: 'pointer',
        display: 'inline-flex',
        fontFamily: `-apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans",
        Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif`,
        
        fontWeight: 1000,
        justifyContent: 'center',
        lineHeight: '20px',
        maxWidth: '600px',
        minHeight: '20px',
        minWidth: '120px',
        textAlign: 'center',
        touchAction: 'manipulation',
        transition: 'background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s',
        userSelect: 'none',
        WebkitUserSelect: 'none',
        verticalAlign: 'middle',
        fontSize: '1vw',
        flex: '0',

        padding: '10px 40px',
        margin: '10px 10px',
        marginTop: '1em',
    },
    'button18:hover': {
        backgroundColor: '#16437E',
        color: '#ffffff',
    },
    'button18:active': {
        background: '#09223b',
        color: 'rgba(255, 255, 255, 0.7)',
    },
    'button18:focus': {
        backgroundColor: '#16437E',
        color: '#ffffff',
    },
    'button18:disabled': {
        cursor: 'not-allowed',
        background: 'rgba(0, 0, 0, 0.08)',
        backgroundColor: '#ccc',
        color: 'rgba(0, 0, 0, 0.3)',
    }
};

const getDeviceStyle = (isTabletOrMobile, isBigScreen) => {
    if (isBigScreen) {
        return {
            fontSize: '0.5vw'
        };
    }

    if (isTabletOrMobile) {
        return {
            fontSize: '2vw',
            borderRadius: '10px',
            lineHeight: '10px',
            maxWidth: '100px',
            minHeight: '10px',
            minWidth: '10px',
        };
    }

    return {};
};

export const Button = ({ idx, onClick, style, text, disabled, isTabletOrMobile, isBigScreen }) => {
    const handleClick = () => {
        if (onClick && !disabled) {
            onClick();
        }
    };

    return (
        <button
            key={idx}
            className={"button-18"}
            style={{ ...buttonStyles.button18, ...style, ...getDeviceStyle(isTabletOrMobile, isBigScreen) }}
            onClick={handleClick}
            disabled={disabled}
        >
            {text}
        </button>
    );
};

export const LeaveButton = (isTabletOrMobile, isBigScreen) => {
    const naviate = useNavigate()
    const leaveGame = () => {
        naviate('/');
    };

    return (
        <button
            className={"button-18"}
            style={{ ...buttonStyles.button18, ...getDeviceStyle(isTabletOrMobile, isBigScreen) }}
            onClick={leaveGame}
        >
            {"Lämna"}
        </button>
    );
};
