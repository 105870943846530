export const HeaderGameTextStyle = (textAlign, isTabletOrMobile, isBigScreen) => {
    if (isTabletOrMobile) {
        return {
            fontFamily: 'Roboto, sans-serif',
            fontSize: '3vw',
            textAlign: textAlign,
        };
    } else if (isBigScreen) {
        return {
            fontFamily: 'Roboto, sans-serif',
            textAlign: textAlign,
            fontSize: '2.5vw',
        };
    } else {
        return {
            fontFamily: 'Roboto, sans-serif',
            textAlign: textAlign,
            fontSize: '1vw',
        };
    }
};

export const GameTextStyle = (textAlign, isTabletOrMobile, isBigScreen) => {
    if (isTabletOrMobile) {
        return {
            fontFamily: 'Roboto, sans-serif',
            fontSize: '2.5vw',
            textAlign: textAlign,
            borderRadius: '10px',
            padding: '0 2%',
        };
    } else if (isBigScreen) {
        return {
            fontFamily: 'Roboto, sans-serif',
            textAlign: textAlign,
            fontSize: '0.6vw',
            padding: '0 2%',
        };
    } else {
        return {
            fontFamily: 'Roboto, sans-serif',
            textAlign: textAlign,
            fontSize: '1vw',
            padding: '0 2%',
        };
    }
};

export const BackgroundStyle = {
    background: `#FDF3EF`,
    padding: 0,
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
};