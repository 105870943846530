import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { Button } from '../Components/Button.js';
import { BackgroundStyle } from '../Components/GeneralStyles.js';
import GameHeader from '../Components/Header';
import { ApiRequest, GameStart } from '../Controllers/ApiRequest.js';

const LobbyButtonsStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '-5vh'
}

const StartPage = () => {
  const [gameId, setGameID] = useState("");
  const navigate = useNavigate();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const isBigScreen = useMediaQuery({ query: '(min-width: 2000px)' });

  function generateUniqueValue() {
    const timestamp = Date.now() % 1000000;
    const randomValue = Math.floor(Math.random() * 10000);

    return (timestamp << 16) | randomValue;
  }

  const handleSoloRoom = async () => {
    const gameId = generateUniqueValue();

    setGameID(gameId);
    navigate(`/game-solo?gameid=${gameId}`);
  };
  const handleCreateRoom = async () => {
    const gameId = generateUniqueValue();
    setGameID(gameId);

    await ApiRequest(GameStart, gameId)
    navigate(`/game?gameid=${gameId}&playerName=${"Player 1"}`);
  };
  const handleJoinRoom = (gameId) => {
    setGameID(gameId)

    navigate(`/game?gameid=${gameId}&playerName=${"Player 2"}`);
  };

  return (
    <div className='lobby' style={{ ...BackgroundStyle, marginBottom: '30px' }}>
      <GameHeader />

      <div style={LobbyButtonsStyle}>
        <Button
          onClick={() => handleSoloRoom()}
          style={{ marginBottom: '10px' }}
          text={"Spela själv"}
          isTabletOrMobile={isTabletOrMobile}
          isBigScreen={isBigScreen}
        />
        <Button
          onClick={() => handleCreateRoom()}
          style={{ marginBottom: '70px' }}
          text={"Skapa ett spelrum"}
          isTabletOrMobile={isTabletOrMobile}
          isBigScreen={isBigScreen}
          disabled={true}
        />

        <div style={{ marginBottom: '10px' }}>
          <input
            type="text"
            id="gameId"
            placeholder="Mata in spelrums id"
            value={gameId}
            onChange={(e) => setGameID(e.target.value)}
            style={{ marginLeft: '5px', padding: '5px' }}
          />
        </div>
        <Button
          onClick={() => handleJoinRoom(gameId)}
          style={{ marginBottom: '50px' }}
          text={"Gå in i spelrum"}
          isTabletOrMobile={isTabletOrMobile}
          isBigScreen={isBigScreen}
          disabled={true}
        />

      </div>
    </div>
  );
};

export default StartPage;
