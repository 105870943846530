import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { BackgroundStyle, GameTextStyle } from '../Components/GeneralStyles.js';
import { Button, buttonStyles } from './Button.js';
import PersonCard from './PersonCard';

const popupStyle = {
  ...BackgroundStyle,
  width: '450px',
  height: '600px',
  border: '4px solid black',
};

const popupInfoStyle = {
  marginLeft: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  width: '50%'
}

const CardPopup = ({ open, onClose, personsData, officeFilteredGame, isTabletOrMobile, isBigScreen }) => {
  return (
    <Popup
      open={open}
      closeOnDocumentClick
      contentStyle={popupStyle}
    >
      {open && personsData && (
        <div>
          <h1 style={{ textAlign: 'center' }}><strong>Motståndaren måste hitta</strong></h1>
          <h1 style={GameTextStyle('center')}>{personsData.fullName}</h1>

          <div style={{ display: 'flex' }}>
            <PersonCard
              person={personsData}
              hideCard={true}
            />
            <div style={popupInfoStyle}>
              <p><strong>Roll:</strong> {personsData.role}</p>
              {personsData.skills && <p><strong>Skills:</strong> {personsData.skills}</p>}
              <p><strong>Kön:</strong> {personsData.gender}</p>
              <p><strong>Uppdrag:</strong> {personsData.assignment}</p>
              {!officeFilteredGame && <p><strong>Kontor:</strong> {personsData.office}</p>}
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Button
              onClick={onClose}
              style={buttonStyles}
              text={"Stäng"}
              isTabletOrMobile={isTabletOrMobile}
              isBigScreen={isBigScreen}
            />
          </div>
        </div>
      )}
    </Popup>
  );
};

export default CardPopup;