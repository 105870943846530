import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button, buttonStyles } from '../Components/Button.js';
import { BackgroundStyle, GameTextStyle } from '../Components/GeneralStyles.js';
import GameHeader from '../Components/Header.js';
import LoadingScreen from '../Components/LoadingScreen';
import PersonCard from '../Components/PersonCard';
import { ApiRequest, NamesData, PersonsData } from '../Controllers/ApiRequest';

const buttonContainerStyle = {
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: 'repeat(2, 1fr)',
    width: '100%',
    marginTop: '20px',
    margin: '0 auto',
    marginBottom: '20px'
};

const popupInfoStyle = {
    marginLeft: '30px',
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    justifyContent: 'center',
}

const cardStyle = {
    display: 'grid',
    gap: '10px',
    justifyContent: 'center',
    width: '60%',
    margin: '0 auto',
    "& > div": {
        display: 'flex',
    }
};

const GuessTheName = ({ gameId, bodyToFindPerson }) => {
    const [personsData, setPersonsData] = useState([]);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [secondLastName, setSecondLastName] = useState("");

    const [buttonLabels, setButtonLabels] = useState([]);
    const [firstNameIndex, setFirstNameIndex] = useState(0);
    const [shuffledButtons, setShuffledButtons] = useState([]);
    const [disabledButtons, setDisabledButtons] = useState([]);
    const [buttonsReady, setButtonsReady] = useState(false);
    const [buttonsDone, setButtonsDone] = useState(false);

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const isLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
    const isBigScreen = useMediaQuery({ query: '(min-width: 2000px)' });

    const [streak, setStreak] = useState(0);

    useEffect(() => {
        let timeoutId;
    
        const fetchData = async () => {
            try {
                const data = await ApiRequest(PersonsData, bodyToFindPerson);
                setPersonsData(data.persons);
            } catch (error) {
                console.error("Error fetching data:", error);
                timeoutId = setTimeout(fetchData, 2000);
            }
        };
    
        if (personsData.length === 0) {
            fetchData();
        }
    
        return () => clearTimeout(timeoutId);
    }, [personsData.length, bodyToFindPerson]);

    useEffect(() => {
        if (personsData && personsData.length === 1) {
            const [fullName] = personsData.map(person => person.fullName);
            const nameParts = fullName.split(' ');

            if (nameParts.length === 2) {
                setFirstName(nameParts[0]);
                setLastName(nameParts[1]);
                setSecondLastName('');
            } else if (nameParts.length >= 3) {
                setFirstName(nameParts[0]);
                setLastName(nameParts[1]);
                setSecondLastName(nameParts[2]);
            }
        }
    }, [personsData]);

    useEffect(() => {
        const fetchData = async (bodyToFindName) => {
            try {
                const data = await ApiRequest(NamesData, bodyToFindName);
                setButtonLabels([data.names[0], data.names[1], data.names[2], data.names[3]])
                setButtonsReady(true)
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        if (personsData && personsData.length !== 0 && personsData[0].gender) {
            const bodyToFindName = {
                office: 'STO',
                numberOfPeople: 3,
                currentName: firstName,
                gender: personsData[0].gender,
                gameId: gameId
            };
            fetchData(bodyToFindName);
        }
    }, [firstName]);

    useEffect(() => {
        if (buttonsReady) {
            const shuffled = buttonLabels.sort(() => Math.random() - 0.5);
            setShuffledButtons(shuffled);
            setFirstNameIndex(shuffled.indexOf(firstName));
            setButtonsDone(true);
        }
    }, [buttonLabels, buttonsReady]);

    const handleUserInput = (label, idx) => {
        const clickedIndex = shuffledButtons.indexOf(label);

        if (clickedIndex === firstNameIndex) {
            setPersonsData([]);
            setDisabledButtons([]);
            setButtonLabels([]);
            setButtonsReady(false);
            setButtonsDone(false);

            setStreak(streak + 1);
        } else {
            setDisabledButtons([...disabledButtons, idx]);

            setStreak(0);
        }
    };

    const getButtonStyle = (idx) => {
        const baseStyle = { ...buttonStyles };
        if (disabledButtons.includes(idx)) {
            return {
                backgroundColor: '#ccc',
                cursor: 'not-allowed',
            };
        }
        return baseStyle;
    };

    const gameTextStyle = (textAlign) => {
        return GameTextStyle(textAlign, isTabletOrMobile, isBigScreen)
    }

    return (
        <div style={BackgroundStyle}>
            <GameHeader
                streak={streak}
                isTabletOrMobile={isTabletOrMobile}
                isBigScreen={isBigScreen}
                isLaptop={isLaptop}
            />

            <div style={cardStyle}>
                {
                    !buttonsDone ? (
                        <LoadingScreen />
                    ) : (
                        personsData.map((person, key) => (
                            <div key={key}>
                                <div style={{ display: 'flex' }}>
                                    <PersonCard
                                        person={person}
                                        hideCard={true}
                                    />
                                    <div style={popupInfoStyle}>
                                        <p style={gameTextStyle('left')}>
                                            Roll <br />
                                            <strong>{person.role}</strong>
                                        </p>
                                        {person.skills && (
                                            <p style={gameTextStyle('left')}>
                                                Skills <br />
                                                <strong>{person.skills}</strong>
                                            </p>
                                        )}
                                        <p style={gameTextStyle('left')}>
                                            Uppdrag <br />
                                            <strong>{person.assignment}</strong>
                                        </p>
                                    </div>
                                </div>

                                <p style={gameTextStyle("center")}>Vad heter personen?</p>
                                <div style={buttonContainerStyle}>
                                    {shuffledButtons.map((label, idx) => (
                                        <Button
                                            key={idx}
                                            onClick={() => handleUserInput(label, idx)}
                                            style={getButtonStyle(idx)}
                                            disabled={disabledButtons.includes(idx)}
                                            text={label}
                                            isTabletOrMobile={isTabletOrMobile}
                                            isBigScreen={isBigScreen}
                                        />
                                    ))}
                                </div>

                            </div>
                        ))
                    )
                }
            </div>

            {/* <LeaveButton isTabletOrMobile={isTabletOrMobile} isBigScreen={isBigScreen} /> */}
        </div>
    );
};


export default GuessTheName;
