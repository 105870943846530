import React from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import StartPage from './Game/GameLobby';
import GuessTheName from './Game/GuessTheName';
import GuessWhoGame from './Game/GuessWhoGame';

const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<StartPage />} />
          <Route path="/game" element={<GameRoutes />} />
          <Route path="/game-solo" element={<SingleGameRoutes />} />
        </Routes>
      </Router>
    </div>
  );
};

const GameRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<GameComponent />}
      />
    </Routes>
  );
};

const SingleGameRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<SingleGameComponent />}
      />
    </Routes>
  );
};

const SingleGameComponent = () => {
  const params = new URLSearchParams(window.location.search);
  const gameId = params.get('gameid');

  if (!gameId) {
    return <Navigate to="/" />;
  }

  const Body = {
    office: 'STO',
    numberOfPeople: 1,
    gameId: gameId
  };

  return <GuessTheName gameId={gameId} bodyToFindPerson={Body} />;
};

const GameComponent = () => {
  const params = new URLSearchParams(window.location.search);
  const gameId = params.get('gameid');
  const playerName = params.get('playerName');

  if (!gameId || !playerName) {
    return <Navigate to="/" />;
  }

  const Body = {
    office: 'STO',
    numberOfPeople: 24,
    gameId: gameId
  };

  return <GuessWhoGame gameId={gameId} playerName={playerName} body={Body}/>;
};

export default App;
